/**
 * A collection of Array reducers.  These functions are intended to be passed
 * into Array.prototype.reduce.
 * @module array/reducers
 */

/**
 * Verifies that an array contains only copies of the boolean value of 'true'
 * @function
 * @name allTrue
 * @param {boolean} collector - The collector value for the array's reduction
 * @param {boolean} bool - The value currently being iterated over
 * @returns {boolean} - True if all values were true, false otherwise
 */
export const allTrue = (collector, bool) => collector && bool;

/**
 * Verifies that an array contains at least one boolean value of 'true'
 * @function
 * @name atLeastOneTrue
 * @param {boolean} hasTrue - Value keeping track of whether a true is present
 * in the array or not.
 * @param {boolean} bool - Current iterated value of the array
 * @returns {boolean} - True if a 'true' was found, false otherwise
 */
export const atLeastOneTrue = (hasTrue, bool) => hasTrue || bool;

/**
 * Averages an array of numbers
 * @function
 * @name avg
 * @param {number} sum - Value which tracks sum of values in array
 * @param {number} n - Number to add to sum before averaging
 * @param {number} i - Current index of array
 * @param {number} arr - Array which reduce is called on
 * @returns {number} - The average of the values in the array
 */
export const avg = (acc, n, i, arr) => {
	if (i === arr.length - 1) return (acc + n) / arr.length;

	return acc + n;
};

/**
 * Finds the maximum value in an array
 * @function
 * @name max
 * @param {number} max - Value which holds current max
 * @param {number} n - Current iterated value of array
 * @returns {number} - The maximum value in the array
 */
export const max = (currentMax, n) => {
	if (n > currentMax) return n;

	return currentMax;
};

/**
 * Finds the minimum value in an array
 * @function
 * @name min
 * @param {number} min - Value which holds current min
 * @param {number} n - Current iterated value of array
 * @returns {number} - The minimum value in the array
 */
export const min = (currentMin, n) => {
	if (n < currentMin) return n;

	return currentMin;
};

/**
 * Converts an array of length 1 to the single item in said array
 * @function
 * @name singleItem
 * @param {*} item - The item in the array
 * @returns {*} - The item in the array
 */
export const singleItem = (collector, item) => item || collector;

/**
 * An array meant to be spread into the Array.prototype.reduce() function as
 * arguments such that an array of unknown (and possibly zero-) length can be
 * reduced in a predictable manner.
 * @name singleItemOrNull
 */
export const singleItemOrNull = [singleItem, null];

/**
 * Sums an array of numbers
 * @function
 * @name sum
 * @param {number} sum - Value which holds current sum of array
 * @param {number} n - Current iterated value of array
 * @returns {number} - The sum of the array's values
 */
export const sum = (currentSum, n) => currentSum + n;
