div(
	class='message-container ' + message.classes
	style='background-color: ' + colors.background + '; color: ' + colors.foreground + ';'
)
	div.text-container
		if message.heading
			span.header #{message.heading}
		div.message !{message.text}
	button.handle(type='button')
		svg(
			alt='Close'
			width=svgSize
			height=svgSize
		)
			line(
				x1=0
				x2=svgSize
				y1=0
				y2=svgSize
			)
			line(
				x1=0
				x2=svgSize
				y1=svgSize
				y2=0
			)
