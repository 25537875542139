import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const Home = async () => await import('~/views/pages/Home');
const NotFound = async () => await import('~/views/pages/404');
const Privacy = async () => await import('~/views/pages/Privacy');
const SearchResults = async () => await import('~/views/pages/SearchResults');

const router = new VueRouter({
	mode: 'history',
	routes: [
		{ path: '/', component: Home },
		{ path: '/privacy', component: Privacy },
		{ path: '/search-results/:terms', component: SearchResults },
		{ path: '*', component: NotFound }
	]
});

export default router;
