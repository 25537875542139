import { allTrue } from '@gabegabegabe/utils/dist/array/reducers';
import devMode from '~/globals/dev-mode';
import { fetchFrontPageMovieSets } from '~/modules/rt';
import log from '~/modules/log';
import { Movie } from '~/classes/Movie';
import { MovieSet } from '~/classes/MovieSet';
import { toKey } from '@gabegabegabe/utils/dist/array/mappers';
import Vue from 'vue';
import Vuex, { Store } from 'vuex';

// Enable Vuex
Vue.use(Vuex);

// Map of JS-friendly keys to human-readable keys for storage use
const storageKeys = {
	cacheTimestamp: 'cache-timestamp',
	searchTerms: 'search-terms',
	searchResults: 'search-results',
	frontPageMovieSets: 'front-page-movie-sets'
};

// Create the store
const store = new Store({
	state: {
		cacheTimestamp: 0,
		searchTerms: false,
		searchResults: [],
		termsForCurrentResults: null,
		frontPageMovieSets: []
	},
	getters: {
		searchResultsNeedRefresh ({ searchTerms, termsForCurrentResults }) {
			return searchTerms === termsForCurrentResults;
		}
	},
	mutations: {
		invalidateCache (state) {
			state.cacheTimestamp = 0;
		},
		setSearchTerms (state, terms) {
			if (terms !== '' && !terms) {
				log.error('Unable to set search terms: No terms provided');

				return;
			}

			if (terms !== '' && typeof terms !== 'string') {
				log.error('Unable to set search terms: Non-string value');

				return;
			}

			if (terms === '') {
				state.searchTerms = false;
				window.sessionStorage.setItem(storageKeys.searchTerms, false);
			} else {
				state.searchTerms = terms;
				window.sessionStorage.setItem(storageKeys.searchTerms, terms);
			}
		},
		setSearchResults (state, movies) {
			if (!movies) return log.error('Unable to set search results: No movies provided');
			if (!Array.isArray(movies) || !movies.map(movie => movie instanceof Movie).reduce(allTrue)) return log.error('Unable to set search results: Invalid movie list');

			state.searchResults = movies;
			state.termsForCurrentResults = state.searchTerms;

			return window.sessionStorage.setItem(storageKeys.searchResults, JSON.stringify(movies));
		},
		setFrontPageMovieSets (state, sets) {
			if (!(sets && Array.isArray(sets))) return log.error('Unable to set front page movie sets: No movie sets provided');

			state.frontPageMovieSets = [...sets];

			return window.localStorage.setItem(storageKeys.frontPageMovieSets, JSON.stringify(state.frontPageMovieSets));
		},
		updateCacheTimestamp (state) {
			state.cacheTimestamp = Date.now();
			window.localStorage.setItem(storageKeys.cacheTimestamp, JSON.stringify(state.cacheTimestamp));
		}
	},
	actions: {
		loadFromCache ({ state, commit, dispatch }, items) {
			Object
				.keys(items)
				.filter(key => true && items[key])
				.forEach(key => {
					const isMovieItem = key === 'frontPageMovieSets';
					try {
						let cachedValue = localStorage.getItem(storageKeys[key]);
						if (devMode) log.info(`${key}: ${cachedValue}`);
						if (key === 'cacheTimestamp') cachedValue = new Date(Number(cachedValue));
						else cachedValue = JSON.parse(cachedValue);

						if (isMovieItem) cachedValue = cachedValue
							.map(({ title, movies }) => new MovieSet({
								title,
								movies: movies.map(movie => new Movie(movie))
							}));

						state[key] = cachedValue;
					} catch (err) {
						if (devMode) log.error(err, `Failed to load ${key} from cache.`);
						else log.error(err);
						commit('invalidateCache');
						dispatch('updateFrontPageCache');
					}
				});
		},
		async updateFrontPageCache ({ commit }) {
			const frontPageMovieSets = await fetchFrontPageMovieSets();
			commit('setFrontPageMovieSets', frontPageMovieSets);
			const moviesFetched = frontPageMovieSets
				.map(toKey('movies'))
				.flat(Infinity)
				.length > 0;

			if (moviesFetched) commit('updateCacheTimestamp');
			else commit('invalidateCache');
		}
	}
});

store.dispatch('loadFromCache', {
	cacheTimestamp: true,
	frontPageMovieSets: true
});

export default store;
