import devMode from '~/globals/dev-mode';
import log from '~/modules/log';

const FAILURE_MESSAGE = 'Failed to register service worker';
const SUCCESS_MESSAGE = 'Successfully registered service worker';
const LOAD_EVENT = 'load';
const SERVICE_WORKER_KEY = 'serviceWorker';
const SERVICE_WORKERS_SUPPORTED = SERVICE_WORKER_KEY in navigator;

const registerServiceWorker = async () => {
	try {
		await navigator.serviceWorker.register('~/service-worker.js', { scope: '/' });
		if (devMode) log.info(SUCCESS_MESSAGE);
	} catch (err) {
		if (devMode) log.error(FAILURE_MESSAGE, err);
	}
};

export const startServiceWorker = () => {
	if (!SERVICE_WORKERS_SUPPORTED) return;

	window.addEventListener(LOAD_EVENT, registerServiceWorker);
};
