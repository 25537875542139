import consola from 'consola';
import notify from '@gabeotisbenson/notify';
import { ocean } from 'base16';

const {
	base08: RED,
	base0A: YELLOW,
	base0B: GREEN
} = ocean;

notify.configure({
	colors: {
		error: RED,
		warn: YELLOW,
		log: GREEN
	}
});

export default {
	error: (err, msg) => {
		consola.error(msg, err);
		if (msg) notify.error(msg);
	},
	info: msg => {
		consola.info(msg);
		notify.log(msg);
	},
	log: msg => {
		consola.log(msg);
		notify.log(msg);
	},
	warn: msg => {
		consola.warn(msg);
		notify.warn(msg);
	}
};
