<template lang="pug">
	div.template-wrapper
		SiteHeader
		SearchForm
		main: router-view
		div.spacer
		SiteFooter(:git-url="gitUrl"): template(slot="privacy-and-license")
			span.privacy: router-link(to="/privacy", data-jslicense="1") Privacy Policy
			span.divisor |
			span.license
				| Content License:
				a(href="https://creativecommons.org/licenses/by-sa/4.0/")  CC-BY-SA-4.0
</template>

<script>
	import cacheLimit from '~/globals/cache-limit';
	import { repository } from '~/../package';

	const SiteFooter = async () => await import('@gabeotisbenson/gabes-foot');
	const SiteHeader = async () => await import('~/views/layout/partials/Header');
	const SearchForm = async () => await import('~/views/SearchForm');

	export default {
		name: 'DefaultLayout',
		components: { SiteHeader, SiteFooter, SearchForm },
		data () {
			return {
				cacheLimit,
				gitUrl: repository.url.replace(/^git\+/u, '').replace(/\.git$/u, '')
			};
		},
		computed: {
			cacheDiff () { return Date.now() - this.cacheTimestamp; },
			cacheOutdated () { return Date.now() - this.cacheTimestamp > cacheLimit; },
			cacheTimestamp () { return this.$store.state.cacheTimestamp; },
			searchTerms () { return this.$store.state.searchTerms; }
		},
		mounted () {
			if (this.cacheOutdated) this.$store.dispatch('updateFrontPageCache');
			if (this.searchTerms) this.$router.push(`/search-results/${this.searchTerms}`);
		}
	};
</script>

<style lang="scss" scoped>
	@import "~/scss/variables";

	div.template-wrapper {
		min-height: 100%;
		display: flex;
		flex-direction: column;

		main {
			width: 100%;
			max-width: $content-width;
			margin: 0 auto;
		}

		div.spacer { flex-grow: 1; }
	}
</style>
