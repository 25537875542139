export class Movie {
	constructor ({ title, url, score, year }) {
		if (!title) throw new Error('Cannot create Movie without title');
		if (!url) throw new Error('Cannot create Movie without url');
		this.title = String(title);
		this.url = String(url);
		// eslint-disable-next-line no-undefined
		if (score !== null && typeof score !== 'undefined') this.score = Number(score);
		// eslint-disable-next-line no-undefined
		if (year !== null && typeof year !== 'undefined') this.year = Number(year);
	}
}
