import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'normalize.css';
import DefaultLayout from '~/views/layout/Default';
import router from '~/modules/router';
import { startServiceWorker } from '~/modules/service-worker';
import store from '~/modules/store';
import Vue from 'vue';

const app = new Vue({
	...DefaultLayout,
	name: 'RTLite',
	store,
	router
}).$mount('#app');

startServiceWorker();

export default app;
