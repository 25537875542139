import { allTrue } from '@gabegabegabe/utils/dist/array/reducers';
import { Movie } from '~/classes/Movie';

export class MovieSet {
	constructor ({ title, movies }) {
		if (!title) throw new Error('Cannot create MovieSet without title');
		if (!movies || !Array.isArray(movies) || movies.length < 1) throw new Error('Cannot create MovieSet without at least one movie');
		const allAreMovies = movies.map(movie => movie instanceof Movie).reduce(allTrue);
		if (!allAreMovies) throw new Error('Invalid movie list');

		this.title = String(title);
		this.movies = movies;
	}
}
