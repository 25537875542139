import consola from 'consola';
import CORS_PROXY from '~/globals/cors-proxy-url';
import { Movie } from '~/classes/Movie';
import { MovieSet } from '~/classes/MovieSet';

const RT_URL = `${'https://www.'}${'rot'}${'tentomatoes.com'}`;
const RT_SEARCH_POSTFIX = '/napi/search/?query=';

const rtElTargets = {
	title: 'span.dynamic-text-list__item-title',
	score: 'span[slot="tomatometer-value"]',
	frontPageMovieList: 'section.dynamic-text-list',
	listTitle: 'h2[slot="header"]',
	listItem: 'ul[slot="list-items"] li'
};

const extractBodyFromPage = page => {
	const start = '<body';
	const end = '</body>';

	return page.substring(page.indexOf(start) + start.length, page.indexOf(end));
};

const extractMovieFromListItem = item => {
	const titleSpan = item.querySelector(rtElTargets.title);
	const scoreSpan = item.querySelector(rtElTargets.score);
	if (!titleSpan) return false;
	const { textContent: title } = titleSpan;
	const score = scoreSpan ? scoreSpan.textContent.replace('%', '') : null;
	const url = RT_URL + item.querySelector('a').getAttribute('href');

	return new Movie({ title, score, url });
};

const extractMovieSetFromFrontPageList = list => {
	const heading = list.querySelector(rtElTargets.listTitle);
	const movieListItems = Array.from(list.querySelectorAll(rtElTargets.listItem));
	if (!heading) return false;
	if (!movieListItems) return false;
	const { textContent: title } = heading;
	const movies = movieListItems
		.map(item => extractMovieFromListItem(item))
		.filter(movie => movie instanceof Movie);

	return new MovieSet({ title, movies });
};

const extractMovieSetsFromBody = body => Array
	.from(body.querySelectorAll(rtElTargets.frontPageMovieList))
	.map(list => extractMovieSetFromFrontPageList(list))
	.filter(movieSet => movieSet instanceof MovieSet);

export const fetchMovies = async terms => {
	if (!terms) return [];

	const encodedTerms = encodeURIComponent(terms.replace(/[^a-zA-Z0-9\s-]/gu, ''));

	try {
		const response = await fetch(CORS_PROXY + RT_URL + RT_SEARCH_POSTFIX + encodedTerms);
		const { movies } = await response.json();

		return movies.map(movie => new Movie({
			title: movie.name,
			year: movie.year,
			score: movie.meterScore,
			url: RT_URL + movie.url
		}));
	} catch (err) {
		consola.error(err);

		return [];
	}
};

export const fetchFrontPageMovieSets = async () => {
	let frontPageMovieSets = [];

	try {
		const response = await fetch(CORS_PROXY + RT_URL);
		const html = await response.text();
		const bodyHtml = extractBodyFromPage(html);
		const fakeBody = document.createElement('body');
		fakeBody.innerHTML = bodyHtml;
		frontPageMovieSets = extractMovieSetsFromBody(fakeBody);
	} catch (err) {
		consola.error(err);
	}

	return frontPageMovieSets;
};
